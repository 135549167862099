import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { OAuthCallback } from '@carto/react-auth';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'store/authUtils';
import TopLoading from 'components/common/TopLoading';
// [hygen] Import views

const Main = lazy(() => import('components/views/Main'));
const Landing = lazy(() => import('components/views/Landing'));
const PublicPlanLanding = lazy(() => import('components/views/PublicPlanLanding'));
const NotFound = lazy(() => import('components/views/NotFound'));

const Explore = lazy(() => import('components/views/explore/Explore'));
const ViewportSummary = lazy(() =>
  import('components/views/explore/viewport-summary/ViewportSummary')
);
const PanelDetails = lazy(() =>
  import('components/views/explore/panel-details/PanelDetails')
);
const PanelDetailsLoader = lazy(() =>
  import('components/views/explore/panel-details/PanelDetailsLoader')
);
const SectionDetailsLoader = lazy(() =>
  import('components/views/explore/panel-details/SectionDetailsLoader')
);
const PanelsGroupLoader = lazy(() =>
  import('components/views/explore/panels-group/PanelsGroupLoader')
);

const Audiences = lazy(() => import('components/views/audiences/Audiences'));
const AudiencesTable = lazy(() => import('components/views/audiences/AudiencesTable'));
const AudienceBuilder = lazy(() =>
  import('components/views/audiences/audience-builder/AudienceBuilder')
);

const Plans = lazy(() => import('components/views/plans/Plans'));

const PlanLoader = lazy(() => import('components/views/explore/plan-details/PlanLoader'));
const Plan = lazy(() => import('components/views/explore/plan-details/Plan'));

function ProtectedRoute({ children }) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  return isLoggedIn ? (
    <Suspense fallback={<TopLoading />}>{children}</Suspense>
  ) : (
    <Navigate to='/landing' />
  );
}

const EXPLORE_CHILDREN = [
  {
    path: 'panels/:panelId',
    element: <PanelDetailsLoader />,
    children: [
      {
        path: ':sectionId',
        element: (
          <SectionDetailsLoader>
            <PanelDetails />
          </SectionDetailsLoader>
        ),
      },
    ],
  },
  {
    path: 'panels-group/:panelsGroupId',
    element: <PanelsGroupLoader />,
  },
];

const routes = [
  {
    path: '/public',
    children: [
      {
        path: ':publicId',
        element: (
          <Suspense fallback={<TopLoading />}>
            <PublicPlanLanding />
          </Suspense>
        ),
        children: [
          {
            path: '',
            element: <ProtectedRoute children={<Main />} />,
            children: [
              {
                path: '',
                element: <Explore />,
                children: [
                  {
                    path: '',
                    element: <PlanLoader />,
                    children: [
                      { path: '', element: <Plan isEdit={false} /> },
                      ...EXPLORE_CHILDREN,
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <ProtectedRoute children={<Main />} />,
    children: [
      {
        path: '/plans',
        element: <Plans />,
      },
      {
        path: '/plans',
        element: <Explore />,
        children: [
          {
            path: ':planId',
            element: <PlanLoader />,
            children: [{ path: '', element: <Plan /> }, ...EXPLORE_CHILDREN],
          },
        ],
      },
      {
        path: '/explore',
        element: <Explore />,
        children: [{ path: '', element: <ViewportSummary /> }, ...EXPLORE_CHILDREN],
      },
      {
        path: '/audiences',
        element: <Audiences />,
        children: [
          { path: '', element: <AudiencesTable /> },
          { path: 'new', element: <AudienceBuilder /> },
          { path: ':audienceId', element: <AudienceBuilder /> },
        ],
      },
      // [hygen] Add routes
    ],
  },
  {
    path: '/landing',
    element: (
      <Suspense fallback={<TopLoading />}>
        <Landing />
      </Suspense>
    ),
  },
  {
    path: '/oauthCallback',
    element: (
      <Suspense fallback={<TopLoading />}>
        <OAuthCallback />
      </Suspense>
    ),
  },
  {
    path: '404',
    element: (
      <Suspense fallback={<TopLoading />}>
        <NotFound />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<TopLoading />}>
        <NotFound />
      </Suspense>
    ),
  },
];

export default routes;
