import { useTheme } from '@material-ui/core';
import { PathLayer, ScatterplotLayer, TextLayer } from 'deck.gl';
import useMapContext from 'hooks/useMapContext';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsRulerEnabled } from 'store/mapSlice';
import hexToRGB from 'utils/hexToRGB';
import turfDistance from '@turf/distance';
import { PathStyleExtension } from '@deck.gl/extensions';

export const RULER_LAYER_ID = 'rulerLayer';

export default function RulerLayer() {
  const theme = useTheme();
  const isRulerEnabled = useSelector(selectIsRulerEnabled);
  const { setController, setMapEvents } = useMapContext();
  const [marks, setMarks] = useState([]);

  const onClick = (e) => {
    if (e) setMarks((oldState) => [...oldState, e.coordinate]);
  };

  useEffect(() => {
    if (isRulerEnabled) {
      setMapEvents({ onClick });
    } else {
      setMarks([]);
      setMapEvents({ onClick: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRulerEnabled]);

  if (isRulerEnabled) {
    return [
      new PathLayer({
        id: `${RULER_LAYER_ID}Path`,
        data: [marks],
        widthUnits: 'pixels',
        getPath: (d) => d,
        getColor: hexToRGB(theme.palette.common.black),
        getWidth: 3,
        extensions: [new PathStyleExtension({ dash: true })],
        getDashArray: [3, 2],
      }),
      new TextLayer({
        id: `${RULER_LAYER_ID}Text`,
        data: marks,
        getPosition: (d) => d,
        getText: (d, { index, data }) => {
          if (index) {
            return (
              [...Array(index + 1)]
                .reduce((acc, _, idx) => {
                  if (idx) {
                    acc += turfDistance(data[idx], data[idx - 1]);
                  }
                  return acc;
                }, 0)
                .toFixed(1) + ' km'
            );
          } else {
            return '0';
          }
        },
        fontFamily: 'Open Sans, Inter, sans-serif',
        fontWeight: 'lighter',
        getSize: 13,
        lineHeight: 0.85,
        background: true,
        getBackgroundColor: hexToRGB(theme.palette.common.white),
        getBorderColor: [...hexToRGB(theme.palette.common.black), 50],
        getBorderWidth: 1,
        backgroundPadding: [8, 6],
        getPixelOffset: [0, 30],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
      }),
      new ScatterplotLayer({
        id: `${RULER_LAYER_ID}Marks`,
        data: marks,
        stroked: true,
        filled: true,
        radiusUnits: 'pixels',
        radiusMinPixels: 1,
        radiusMaxPixels: 15,
        getRadius: 7,
        lineWidthUnits: 'pixels',
        lineWidthMinPixels: 2,
        lineWidthMaxPixels: 6,
        getPosition: (d) => d,
        getFillColor: hexToRGB(theme.palette.common.white),
        getLineColor: hexToRGB(theme.palette.common.black),
        // Interactivity
        pickable: true,
        onDragStart: () => {
          setController(false);
        },
        onDrag: (e) => {
          if (e) {
            const marksCp = [...marks];
            marksCp[e.index] = e.coordinate;
            setMarks(marksCp);
          }
        },
        onDragEnd: () => {
          setController(true);
        },
        onHover: (info) => {
          if (info?.object) {
            info.object = null;
          }
        },
      }),
    ];
  }
}
