import { createSlice } from '@reduxjs/toolkit';
import { BOUNDARIES } from 'data/models/BoundariesModel';
import { getPoisTable } from 'data/models/PoisModel';
import { AUDIENCE_OPTIONS_BY_INDEX } from 'utils/constants';
import metadata, { selectDataset } from 'utils/metadataUtils';
import parseHierarchicalData from 'utils/parseHierarchicalData';

const DEFAULT_BUFFER_SIZE = 500;

const INITIAL_BOUNDARIES = {
  isBoundariesEnabled: false,
  selectedBoundariesTree: {}, // { [relationColumn]: [selectedItems] }
  selectedDrawMode: false,
  drawFeatures: {
    type: 'FeatureCollection',
    features: [],
  },
};

const INITIAL_AUDIENCES = {
  isAudiencesEnabled: false,
  selectedAudience: null,
  bestPanels: 35,
  audienceFilterType: null,
  audienceIntervals: AUDIENCE_OPTIONS_BY_INDEX,
};

const INITIAL_PROXIMITY = {
  isProximityEnabled: false,
  selectedCategoriesTree: {}, // { [type]: [selectedItems] }
  proximityState: {
    distance: DEFAULT_BUFFER_SIZE,
    numberPanels: 1,
    onlyCustomPois: false,
    // If false, only pois with panels inside their buffers are shown
    // If true, show all pois
    showAllPois: false,
  },
  // Used to animate buffer size in PoisLayer,
  // instead of proximityState.distance that is used
  // in queries and only setted when slider drag end event is fired.
  bufferRadius: DEFAULT_BUFFER_SIZE,
  // Uploaded custom pois
  customPois: [],
};

const INITIAL_PANELS = {
  isPanelsFilterEnabled: false,
  panelIdsFilter: [],
};

const slice = createSlice({
  name: 'filters',
  initialState: {
    // Boundaries
    ...INITIAL_BOUNDARIES,
    // Audiences
    ...INITIAL_AUDIENCES,
    // Proximity
    ...INITIAL_PROXIMITY,
    // Panels
    ...INITIAL_PANELS,
  },
  reducers: {
    // Boundaries
    setIsBoundariesEnabled: (state, action) => {
      state.isBoundariesEnabled = action.payload;
    },
    setSelectedBoundariesTree: (state, action) => {
      state.selectedBoundariesTree = action.payload;
    },
    setSelectedBoundaries: (state, action) => {
      const { type, selected } = action.payload;

      if (selected?.length) {
        state.selectedBoundariesTree = {
          ...state.selectedBoundariesTree,
          [type]: selected,
        };
      } else {
        delete state.selectedBoundariesTree[type];
      }
    },
    setSelectedDrawMode: (state, action) => {
      state.selectedDrawMode = action.payload;
    },
    setDrawFeatures: (state, action) => {
      state.drawFeatures = action.payload;
    },
    resetBoundaries: (state) => {
      Object.keys(INITIAL_BOUNDARIES).forEach((key) => {
        state[key] = INITIAL_BOUNDARIES[key];
      });
    },
    // Audiences
    setIsAudiencesEnabled: (state, action) => {
      state.isAudiencesEnabled = action.payload;
    },
    setSelectedAudience: (state, action) => {
      state.selectedAudience = action.payload;
    },
    setBestPanels: (state, action) => {
      state.bestPanels = parseInt(action.payload);
    },
    setAudienceFilterType: (state, action) => {
      state.audienceFilterType = action.payload;
    },
    setAudienceIntervals: (state, action) => {
      state.audienceIntervals = action.payload;
    },
    resetAudiences: (state) => {
      Object.keys(INITIAL_AUDIENCES).forEach((key) => {
        state[key] = INITIAL_AUDIENCES[key];
      });
    },
    // Proximity
    setIsProximityEnabled: (state, action) => {
      state.isProximityEnabled = action.payload;
    },
    setSelectedCategories: (state, action) => {
      const { type, selected } = action.payload;

      if (selected?.length) {
        state.selectedCategoriesTree = {
          ...state.selectedCategoriesTree,
          [type]: selected,
        };
      } else {
        delete state.selectedCategoriesTree[type];
      }
    },
    setSelectedCategoriesTree: (state, action) => {
      state.selectedCategoriesTree = action.payload;
    },
    setProximityState: (state, action) => {
      state.proximityState = action.payload;
    },
    setProximityDistance: (state, action) => {
      state.proximityState = {
        ...state.proximityState,
        distance: action.payload,
      };
    },
    setProximityNumberPanels: (state, action) => {
      state.proximityState = {
        ...state.proximityState,
        numberPanels: action.payload,
      };
    },
    setProximityShowAllPois: (state, action) => {
      state.proximityState = {
        ...state.proximityState,
        showAllPois: action.payload,
      };
    },
    setProximityOnlyCustomPois: (state, action) => {
      state.proximityState = {
        ...state.proximityState,
        onlyCustomPois: action.payload,
      };
    },
    setBufferRadius: (state, action) => {
      state.bufferRadius = action.payload;
    },
    resetProximity: (state) => {
      Object.keys(INITIAL_PROXIMITY).forEach((key) => {
        state[key] = INITIAL_PROXIMITY[key];
      });
    },
    setCustomPois: (state, action) => {
      state.customPois = action.payload;
    },
    setPanelIdsFilter: (state, action) => {
      state.panelIdsFilter = action.payload;
    },
    setIsPanelsFilterEnabled: (state, action) => {
      state.isPanelsFilterEnabled = action.payload;
    },
  },
});

export default slice.reducer;

// ---- Mutations ----

// Boundaries
export const setIsBoundariesEnabled = (payload) => ({
  type: 'filters/setIsBoundariesEnabled',
  payload,
});
export const setSelectedBoundariesTree = (payload) => ({
  type: 'filters/setSelectedBoundariesTree',
  payload,
});
export const setSelectedBoundaries = (type, selected) => ({
  type: 'filters/setSelectedBoundaries',
  payload: { type, selected },
});
export const resetBoundaries = () => ({
  type: 'filters/resetBoundaries',
});
export const setSelectedDrawMode = (payload) => ({
  type: 'filters/setSelectedDrawMode',
  payload,
});
export const setDrawFeatures = (payload) => ({
  type: 'filters/setDrawFeatures',
  payload,
});

// Audiences
export const setIsAudiencesEnabled = (payload) => ({
  type: 'filters/setIsAudiencesEnabled',
  payload,
});
export const setSelectedAudience = (payload) => ({
  type: 'filters/setSelectedAudience',
  payload,
});
export const setBestPanels = (payload) => ({
  type: 'filters/setBestPanels',
  payload,
});
export const setAudienceFilterType = (payload) => ({
  type: 'filters/setAudienceFilterType',
  payload,
});
export const setAudienceIntervals = (payload) => ({
  type: 'filters/setAudienceIntervals',
  payload,
});
export const resetAudiences = () => ({
  type: 'filters/resetAudiences',
});

// Proximity
export const setIsProximityEnabled = (payload) => ({
  type: 'filters/setIsProximityEnabled',
  payload,
});
export const setSelectedCategories = (type, selected) => ({
  type: 'filters/setSelectedCategories',
  payload: { type, selected },
});
export const setSelectedCategoriesTree = (payload) => ({
  type: 'filters/setSelectedCategoriesTree',
  payload,
});
export const setProximityState = (payload) => ({
  type: 'filters/setProximityState',
  payload,
});
export const setProximityDistance = (payload) => ({
  type: 'filters/setProximityDistance',
  payload,
});
export const setProximityNumberPanels = (payload) => ({
  type: 'filters/setProximityNumberPanels',
  payload,
});
export const setProximityShowAllPois = (payload) => ({
  type: 'filters/setProximityShowAllPois',
  payload,
});
export const setProximityOnlyCustomPois = (payload) => ({
  type: 'filters/setProximityOnlyCustomPois',
  payload,
});
export const setBufferRadius = (payload) => ({
  type: 'filters/setBufferRadius',
  payload,
});
export const resetProximity = () => ({
  type: 'filters/resetProximity',
});
export const setCustomPois = (payload) => ({
  type: 'filters/setCustomPois',
  payload,
});

// Panels
export const setPanelIdsFilter = (payload) => ({
  type: 'filters/setPanelIdsFilter',
  payload,
});
export const setIsPanelsFilterEnabled = (payload) => ({
  type: 'filters/setIsPanelsFilterEnabled',
  payload,
});

// ---- Selectors ----

// Boundaries
export const selectIsBoundariesEnabled = (state) => state.filters.isBoundariesEnabled;
export const selectRawSelectedBoundariesTree = (state) =>
  state.filters.selectedBoundariesTree;
export const selectSelectedBoundariesTree = (state) => {
  const selectedBoundariesTree = selectRawSelectedBoundariesTree(state);
  const boundariesTree = selectDataset(BOUNDARIES, state.app.country);

  return parseHierarchicalData({
    itemsTree: boundariesTree,
    selectedItemsTree: selectedBoundariesTree,
    uniqueProperty: 'relationColumn',
  });
};
export const selectSelectedBoundariesIdsTree = (state) => {
  const parsedSelectedBoundaries = selectSelectedBoundariesTree(state);

  // Transform { key: { raw objects }} to { key: [ids] }
  return Object.entries(parsedSelectedBoundaries).reduce((acc, [key, values]) => {
    acc[key] = values.map(({ id }) => id);
    return acc;
  }, {});
};
export const selectSelectedBoundaries = (boundaryId) => (state) =>
  state.filters.selectedBoundariesTree[boundaryId];
export const selectSelectedDrawMode = (state) => state.filters.selectedDrawMode;
export const selectDrawFeatures = (state) => state.filters.drawFeatures;

// Audiences
export const selectIsAudiencesEnabled = (state) => state.filters.isAudiencesEnabled;
export const selectSelectedAudience = (state) => state.filters.selectedAudience;
export const selectBestPanels = (state) => state.filters.bestPanels;
export const selectAudienceFilterType = (state) => state.filters.audienceFilterType;
export const selectAudienceIntervals = (state) => state.filters.audienceIntervals;

// Proximity
export const selectIsProximityEnabled = (state) => state.filters.isProximityEnabled;
export const selectRawSelectedCategoriesTree = (state) =>
  state.filters.selectedCategoriesTree;
export const selectSelectedCategoriesIdsTree = (state) => {
  const { categoriesTree } = getPoisTable(metadata, state.app.country) || {};

  const selectedCategoriesTree = Object.entries(
    state.filters.selectedCategoriesTree
  ).reduce((acc, [categoryType, categories]) => {
    acc[categoryType] = categories.map(({ grouped }) => grouped).flat();
    return acc;
  }, {});

  const parsedSelectedCategories = parseHierarchicalData({
    itemsTree: categoriesTree,
    selectedItemsTree: selectedCategoriesTree,
    uniqueProperty: 'column',
  });

  // Transform { key: { raw objects }} to { `{key}_id`: [ids] }
  return Object.entries(parsedSelectedCategories).reduce((acc, [key, values]) => {
    acc[`${key}_id`] = values.map(({ id }) => id);
    return acc;
  }, {});
};

export const selectSelectedCategories = (category) => (state) =>
  selectSelectedCategoriesIdsTree(state)[category];

export const selectProximityState = (state) => state.filters.proximityState;
export const selectProximityDistance = (state) => state.filters.proximityState.distance;
export const selectProximityNumberPanels = (state) =>
  state.filters.proximityState.numberPanels;
export const selectProximityShowAllPois = (state) =>
  state.filters.proximityState.showAllPois;
export const selectProximityOnlyCustomPois = (state) =>
  state.filters.proximityState.onlyCustomPois;
export const selectBufferRadius = (state) => state.filters.bufferRadius;
export const selectCustomPois = (state) => state.filters.customPois;

// Panels
export const selectPanelIdsFilter = (state) => state.filters.panelIdsFilter;
export const selectIsPanelsFilterEnabled = (state) => state.filters.isPanelsFilterEnabled;
