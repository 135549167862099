import { createSelector } from '@reduxjs/toolkit';
import { selectOAuthCredentials } from '@carto/react-redux';
import { selectPublicCredentials } from 'store/appSlice';

export function isLoggedInSelector(state) {
  return !!state.app.publicCredentials || !!state.oauth.userInfo;
}

export const selectCredentials = createSelector(
  [
    selectOAuthCredentials,
    selectPublicCredentials,
    (state) =>
      selectPublicCredentials(state)?.region || state.oauth.token?.region || 'eu',
  ],
  (oauthCredentials, publicCredentials, region) => {
    const credentials = publicCredentials || oauthCredentials;
    return { ...credentials, region };
  }
);

let alreadyInAction = false;

export async function logout() {
  if (!alreadyInAction) {
    alreadyInAction = true;
    localStorage.removeItem('cra-carto');
    const url = 'https://carto.com/logout'; // environment.carto.baseUrl.replace('{username}', this.data.user.username) + '/logout';

    const logoutWindow = window.open(url, '', 'width=100,height=100');
    setTimeout(() => {
      logoutWindow.close();
      window.location.reload();
      alreadyInAction = false;
    }, 500);
  }
}
