import { getFieldId } from 'utils/metadataUtils';

const PANELS_DATASET = 'panels'

// Used to get the id of a panels group.
// The id is the join of each panel's id
export function formatPanelsGroupId(panelsGroup) {
  return (panelsGroup?.properties.features || [])
    .map((panel) => getProperties(panel).id)
    .sort()
    .join('-');
}

// Get name from panel feature
export function getPanelName(panel) {
  const fieldId = getFieldId(PANELS_DATASET)
  const properties = getProperties(panel);
  return properties.name || properties[fieldId] || 'A panel';
}

export function getPanelListName(panel) {
  const address = getProperties(panel).address || '';
  return `ID ${getPanelName(panel)}${address ? ` (${address})` : ''}`;
}

export function isGroup(panel) {
  return getProperties(panel).group_count > 1;
}

export function isCluster(panel) {
  return getProperties(panel).cluster;
}

export function isSamePanel(panel, otherPanel) {
  if (isGroup(panel) && isGroup(otherPanel)) {
    return formatPanelsGroupId(panel) === formatPanelsGroupId(otherPanel);
  } else if (isGroup(panel) || isGroup(otherPanel)) {
    return false;
  }

  return getProperties(panel).id === getProperties(otherPanel).id;
}

// Aux
export function getProperties(panel) {
  return panel?.properties || panel || {};
}
