import { executeSQL } from '@carto/react-api';

export const POIS = 'pois';

export function getCategoriesQuery({
  column,
  tableName,
  upperCategory,
  upperCategories = [],
  selectedIds = [],
  searchText,
}) {
  const parentsSelect = upperCategories.length
    ? `,ARRAY[${upperCategories.map(({ column }) => `${column}_id`).join()} ] AS parents`
    : '';

  const upperCategoryFilter =
    !!upperCategory?.selected?.length &&
    `${upperCategory.column}_id IN (${upperCategory.selected.join()})`;

  const searchFilter =
    !!searchText &&
    `${tableName}.${column}_tsvector @@ to_tsquery('simple', '${searchText
      .replace("'", "''")
      .trim()
      .split(' ')
      .map((word) => word + ':*')
      .join(' & ')}')`;

  const selectedIdsFilter = !!selectedIds?.length && `id IN (${selectedIds.join()})`;

  const whereClause = [upperCategoryFilter, searchFilter, selectedIdsFilter]
    .filter(Boolean)
    .join(' AND ');

  return `
    SELECT
      ${column} as category,
      id
      ${parentsSelect}
    FROM
      ${tableName}
    WHERE
      ${whereClause || 'TRUE'}
    ORDER BY ${column}
    LIMIT 1000
  `;
}

export async function getCategories(
  metadata,
  credentials,
  { abortController, column, tableName, upperCategory, upperCategories = [], searchText }
) {
  const query = getCategoriesQuery({
    column,
    tableName,
    upperCategory,
    upperCategories,
    searchText,
  });

  const data = await executeSQL({ credentials, query, opts: { abortController } });

  return groupPoisDataByLabel(data);
}

// Aux
export function groupPoisDataByLabel(data) {
  const groupedDataByCategoryLabel = (data || []).reduce((acc, item) => {
    const repeated = acc.get(item.category) || [];
    acc.set(item.category, [...repeated, item]);

    return acc;
  }, new Map());

  return Array.from(groupedDataByCategoryLabel.entries()).map(([label, categories]) => ({
    id: categories.map(({ id }) => id).join(),
    category: label,
    grouped: categories,
  }));
}

export function getPoisTable(metadata, country = 'esp') {
  const org = metadata.organization.owner.username;
  const poisMetadata = metadata.datasets.pois[country] || metadata.datasets.pois;
  const poisTable = `"${org}".${poisMetadata.tableName}`;
  const poisTilesets = poisMetadata.tilesets.reduce((info, properties) => {
    info[properties.type] = properties;
    return info;
  }, {});
  const distanceTableName = `"${org}".${poisMetadata.distanceTableName}`;
  const categoriesTree = poisMetadata.categoriesTree.map((category) => ({
    ...category,
    tableName: `"${org}".${category.tableName}`,
  }));
  const categoriesTreeWithSelector = categoriesTree.filter(
    (level) => level.showSelector !== false
  );
  return {
    poisTable,
    poisTilesets,
    distanceTableName,
    panelsDistanceRelationColumn: poisMetadata.panelsDistanceRelationColumn,
    categoriesTree,
    categoriesTreeWithSelector,
    provider: poisMetadata.provider,
    poisMetadata,
  };
}
