export const WIDGETS = {
  FORMULA: 'formula',
  HISTOGRAM: 'histogram',
  CATEGORY: 'category',
  PIE: 'pie',
};

export const MODULE_MODELS_PATH = 'data/models';
export const MODULE_LEGENDS_PATH = 'components/legends';

export const TRANSITION_STATE = {
  STARTS: 'starts',
  ENDS: 'ends',
};

export const SHARED_PLAN_USER_INFO = {
  username: '',
  avatar_url: '',
};

export const MIN_ZOOM = 1;
export const MAX_ZOOM = 20;

export const AUDIENCE_BY_INDEX = 'index';
export const AUDIENCE_BY_BEST_PANELS = 'best panels';
export const AUDIENCE_OPTIONS_BY_INDEX = [
  { id: 4, name: 'Top', value: 'Top' },
  { id: 3, name: 'High', value: 'High' },
  { id: 2, name: 'Medium', value: 'Medium' },
  { id: 1, name: 'Low-medium', value: 'Low-medium' },
  { id: 0, name: 'Low', value: 'Low' },
];

export const DRAW_OPTIONS = {
  POLYGON: 'DrawPolygonMode',
  SQUARE: 'DrawRectangleMode',
  CIRCLE: 'DrawCircleFromCenterMode',
  LASSO: 'DrawPolygonByDraggingMode',
  EDIT: 'edit',
};
