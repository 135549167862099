import { createSlice } from '@reduxjs/toolkit';
import { RULER_LAYER_ID } from 'components/layers/RulerLayer';

const slice = createSlice({
  name: 'map',
  initialState: {
    flightState: null,
  },
  reducers: {
    setFlightState: (state, action) => {
      state.flightState = action.payload;
    },
  },
});

export default slice.reducer;

// Mutations
export const setFlightState = (payload) => ({
  type: 'map/setFlightState',
  payload,
});

// Selectors
export const selectFlightState = (state) => state.map.flightState;

export const selectIsRulerEnabled = (state) => !!state.carto.layers[RULER_LAYER_ID];
