import { createSlice } from '@reduxjs/toolkit';
import { formatPanelsGroupId } from 'utils/panelsUtilities';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    country: 'esp',
    infoWindow: null,
    publicCredentials: null,
    // Panel
    selectedPanel: null,
    selectedPanelSection: null,
    selectedPanelsGroup: null,
    // Plan
    selectedPlan: null,
    isEditingPlan: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setInfoWindow: (state, action) => {
      state.infoWindow = action.payload;
    },
    setPublicCredentials: (state, action) => {
      state.publicCredentials = action.payload;
    },
    // Panel
    setSelectedPanel: (state, action) => {
      state.selectedPanel = action.payload;
    },
    setSelectedPanelSection: (state, action) => {
      state.selectedPanelSection = action.payload;
    },
    setSelectedPanelsGroup: (state, action) => {
      state.selectedPanelsGroup = action.payload;
    },
    // Plan
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setIsEditingPlan: (state, action) => {
      state.isEditingPlan = action.payload;
    },
  },
});

export default slice.reducer;

// Mutations
export const setError = (payload) => ({ type: 'app/setError', payload });

export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});

export const setInfoWindow = (payload) => ({
  type: 'app/setInfoWindow',
  payload,
});

export const setCountry = (payload) => ({
  type: 'app/setCountry',
  payload,
});

export const setSelectedPanel = (payload) => ({
  type: 'app/setSelectedPanel',
  payload,
});

export const setSelectedPanelSection = (payload) => ({
  type: 'app/setSelectedPanelSection',
  payload,
});

export const setSelectedPanelsGroup = (payload) => ({
  type: 'app/setSelectedPanelsGroup',
  payload,
});

export const setSelectedPlan = (payload) => ({
  type: 'app/setSelectedPlan',
  payload,
});

export const setIsEditingPlan = (payload) => ({
  type: 'app/setIsEditingPlan',
  payload,
});

export const setPublicCredentials = (payload) => ({
  type: 'app/setPublicCredentials',
  payload,
});

// Selectors
export const selectIsoline = (state) => state.app.isoline?.data;
export const selectInfoWindow = (state) => state.app.infoWindow;
export const selectCountry = (state) => state.app.country;
export const selectSelectedPanel = (state) => state.app.selectedPanel;
export const selectSelectedPanelSection = (state) => state.app.selectedPanelSection;
export const selectSelectedPanelsGroup = (state) => state.app.selectedPanelsGroup;
export const selectSelectedPanelsGroupId = (state) => {
  const selectedPanelsGroup = selectSelectedPanelsGroup(state);
  return formatPanelsGroupId(selectedPanelsGroup);
};
export const selectSelectedPlan = (state) => state.app.selectedPlan;
export const selectIsEditingPlan = (state) => state.app.isEditingPlan;
export const selectPublicCredentials = (state) => state.app.publicCredentials;

// Miscelanea for CARTO
export const selectZoom = (state) => Math.round(state.carto.viewState.zoom);
