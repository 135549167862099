import { createContext, useRef, useState } from 'react';

export const MapEventsContext = createContext();

export function MapEventsContextProvider({ children }) {
  const deckRef = useRef();
  const mapboxRef = useRef();
  const [mapEvents, setMapEvents] = useState({});
  const [controller, setController] = useState(true);

  return (
    <MapEventsContext.Provider
      value={{ mapEvents, setMapEvents, deckRef, mapboxRef, controller, setController }}
    >
      {children}
    </MapEventsContext.Provider>
  );
}
