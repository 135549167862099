import { executeSQL } from '@carto/react-api';
import { formatTableName } from 'utils/metadataUtils';

export const BOUNDARIES = 'boundaries';

export function getBoundariesByIds(
  credentials,
  {
    abortController,
    boundary,
    upperBoundaries,
    lowerBoundaries = [],
    boundariesIds,
    useFamily = true,
  }
) {
  const parentsSelect = useFamily ? formatParentsSelect(upperBoundaries) : '';
  const [childrenSelect, childrenJoin, childrenGroupBy] = useFamily
    ? formatLowerSelect(boundary, upperBoundaries, lowerBoundaries)
    : ['', '', ''];

  const query = `
  SELECT
    boundary.id,
    boundary.name
    ${parentsSelect}
    ${childrenSelect}
  FROM
    ${formatTableName(boundary.tableName)} boundary
    ${childrenJoin}
  WHERE
    boundary.has_panels IS TRUE
    AND id IN (${processIdsForInFilter(boundariesIds)})
  ${childrenGroupBy}
  `;

  return executeSQL({ credentials, query, opts: { abortController } });
}

export function getBoundaries(
  _,
  credentials,
  {
    abortController,
    boundary,
    upperBoundaries,
    lowerBoundaries = [],
    selectedUpperBoundariesIds,
  }
) {
  const selectedUpperBoundariesFilter = Object.entries(selectedUpperBoundariesIds)
    .map(
      ([relationColumn, boundariesIds]) =>
        `boundary.${relationColumn} IN (${processIdsForInFilter(boundariesIds)})`
    )
    .join(' AND ');

  const parentsSelect = formatParentsSelect(upperBoundaries);
  const [childrenSelect, childrenJoin, childrenGroupBy] = formatLowerSelect(
    boundary,
    upperBoundaries,
    lowerBoundaries
  );

  const query = `
  SELECT
    boundary.id,
    boundary.name
    ${parentsSelect}
    ${childrenSelect}
  FROM
    ${formatTableName(boundary.tableName)} boundary
    ${childrenJoin}
  WHERE
    boundary.has_panels IS TRUE
    AND ${selectedUpperBoundariesFilter || 'TRUE'}
  ${childrenGroupBy}
  ORDER BY
    boundary.name
  `;

  return executeSQL({ credentials, query, opts: { abortController } });
}

export function searchBoundaries(
  _,
  credentials,
  { abortController, boundaryCategory, searchText }
) {
  const query = `
  SELECT
    boundary.id,
    boundary.name
  FROM
    ${formatTableName(boundaryCategory.tableName)} boundary
  WHERE
    boundary.name ILIKE '%${searchText}%'
  ORDER BY
    boundary.name
  `;

  return executeSQL({ credentials, query, opts: { abortController } });
}

// Aux
function formatParentsSelect(upperBoundaries) {
  if (!upperBoundaries.length) {
    return '';
  }

  return `,ARRAY[${upperBoundaries
    .map(({ relationColumn }) => 'boundary.' + relationColumn)
    .join()} ] AS parents`;
}

function formatLowerSelect(boundary, upperBoundaries, lowerBoundaries) {
  if (!lowerBoundaries.length) {
    return ['', '', ''];
  }

  const upperBoundariesSelector = upperBoundaries.length
    ? ',' + upperBoundaries.map(({ relationColumn }) => 'boundary.' + relationColumn)
    : '';

  return [
    lowerBoundaries
      .map(
        ({ tableName, relationColumn }) =>
          `,array_agg(${tableName}.id) FILTER (WHERE ${tableName}.id IS NOT null) as ${relationColumn}_children`
      )
      .join(' '),
    lowerBoundaries
      .map(
        ({ tableName }) =>
          `LEFT JOIN ${formatTableName(tableName)} ${tableName}
            ON ${tableName}.${boundary.relationColumn} = boundary.id
            AND ${tableName}.has_panels IS TRUE`
      )
      .join(' '),
    `GROUP BY boundary.id, boundary.name${upperBoundariesSelector}`,
  ];
}

function processIdsForInFilter(ids) {
  return ids.map((id) => (Number.isFinite(id) ? id : `'${id}'`));
}
