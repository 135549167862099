import { BOUNDARIES } from 'data/models/BoundariesModel';
import { MAX_ZOOM, MIN_ZOOM } from './constants';

const metadata = {
  bucketBaseUrl: null,
  set(newMetadata) {
    // Metadata can only be set once
    if (!this.loaded) {
      Object.entries(newMetadata).forEach(([key, value]) => {
        this[key] = value;
      });
      this.loaded = true;
      this.bucketBaseUrl = `https://storage.googleapis.com/ooh-product-${newMetadata.organization.name}/static`;
    }
  },
};

export default metadata;

// Metadata selectors
export const selectDataset = (dataset, country) =>
  metadata.datasets?.[dataset]?.[country] || metadata.datasets?.[dataset];

export const hasDataset = (dataset, country) => !!selectDataset(dataset, country);

export const selectDatasetTilesets = (dataset, country) =>
  selectDataset(dataset, country)?.tilesets;

export const selectDatasetTileset = (dataset, country, zoom) =>
  selectDatasetTilesets(dataset, country)?.find(
    ({ zoom_min = MIN_ZOOM, zoom_max = MAX_ZOOM }) =>
      zoom >= Math.max(MIN_ZOOM, zoom_min) && zoom <= Math.min(MAX_ZOOM, zoom_max)
  ) || {};

export const selectDatasetTilesetZoomLimits = (dataset, country) => {
  const tilesets = selectDatasetTilesets(dataset, country);

  const minZoom = Math.min(...tilesets.map(({ zoom_min = 0 }) => zoom_min));
  const maxZoom = Math.max(...tilesets.map(({ zoom_max = 20 }) => zoom_max));

  return { minZoom, maxZoom };
};

export const selectLayerViz = (layerId, dataset, country) => {
  return selectDataset(dataset, country)?.layers?.[layerId] || {};
};

export const selectName = (dataset, country) =>
  selectDataset(dataset, country)?.name || dataset;

export const selectNamePlural = (dataset, country) =>
  selectDataset(dataset, country)?.namePlural || selectName(dataset);

export const selectFields = (dataset, country) => {
  return selectDataset(dataset, country)?.fields || [];
};

export const selectField = (column, dataset, country) => {
  const fields = selectFields(dataset, country)
  return fields?.find((field) => {
    return field.column === column;
  });
};

export const getFieldId = (dataset, country) => {
  const fieldId = selectFields(dataset, country)?.find((field) => {
    return 'columnId' in field && field.columnId;
  });
  return fieldId && fieldId.column ? fieldId.column : 'id'
};

export const selectFieldName = (column, dataset, country) =>
  selectField(column, dataset, country)?.name || '';

export const selectFieldNamePlural = (column, dataset, country) =>
  selectField(column, dataset, country)?.namePlural || '';

export const selectHighlightedField = (dataset, country) => {
  return selectFields(dataset, country)?.find((field) => field.showInTooltip);
};

export const selectDetailsFields = (dataset, country) => {
  return selectFields(dataset, country)?.filter((field) => field.showInDetails);
};

export const selectWidgetFields = (dataset, country) => {
  return selectFields(dataset, country)?.filter((field) => field.showWidget);
};

export const selectPanelsFilteredColumn = () => {
  return (
    selectFields('panels')?.find((field) => field.panelListFilterTarget) || {
      column: 'id',
      type: 'number',
    }
  );
};

export const FIELD_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
};

export const defaultSelectClauseByField = ({ column }, tableAlias) =>
  `${tableAlias ? `${tableAlias}.` : ''}${column}`;

export const stringSelectClause = ({ column }, tableAlias) =>
  `COALESCE(NULLIF(${tableAlias ? `${tableAlias}.` : ''}${column},''), 'Unknown')`;

export const SELECT_FORMATTER_BY_FIELD_TYPE = {
  [FIELD_TYPE.STRING]: stringSelectClause,
  [FIELD_TYPE.NUMBER]: defaultSelectClauseByField,
  [FIELD_TYPE.DATE]: defaultSelectClauseByField,
  [FIELD_TYPE.BOOLEAN]: (field, tableAlias) =>
    `CASE WHEN ${defaultSelectClauseByField(
      field,
      tableAlias
    )} = true THEN 'Yes' WHEN ${defaultSelectClauseByField(
      field
    )} = false THEN 'No' ELSE 'No' END`,
};

// Measurements
export const MEASUREMENTS = {
  DISTANCE: 'distance',
  CURRENCY: 'currency',
  OPTIMIZATION: 'optimization',
};

export const selectMeasurement = (measurement, dataset) =>
  selectDataset(dataset)?.measurements?.[measurement] ||
  metadata.measurements?.[measurement];

// Custom selectors
export const selectBoundariesKeys = (country) =>
  selectDataset(BOUNDARIES, country).map(({ relationColumn }) => relationColumn);

export const formatTableName = (tableName) => {
  const organization = metadata.organization.owner.username;
  return `"${organization}".${tableName}`;
};

// Coverage
export const selectIsFileCoverage = () => {
  return metadata.coverage?.type === 'file';
};
