import { MapEventsContext } from 'components/common/MapEventsContext';
import { useContext } from 'react';

export default function useMapContext() {
  let { deckRef, mapboxRef, ...mapContext } = useContext(MapEventsContext);
  if (!deckRef.current?.deck && window.cartoDeck) {
    deckRef.current = { deck: window.cartoDeck._deck };
  }
  return { deckRef, mapboxRef, ...mapContext };
}
