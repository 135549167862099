export default function hexToRGB(data) {
  let hex = data.charAt(0) === '#' ? data.substr(1) : data;

  if (hex.length < 2 || hex.length > 6) {
    return false;
  }

  const values = hex.split('');

  const lengths = {
    2: lengthTwo,
    3: lengthThree,
    6: lengthSix,
  };

  const fn = lengths[hex.length];

  if (!fn) {
    return false;
  }

  const { r, g, b } = fn(values);

  return [r, g, b];
}

function lengthTwo(values) {
  const r = parseInt(values[0].toString() + values[1].toString(), 16);

  return {
    r,
    g: r,
    b: r,
  };
}

function lengthThree(values) {
  return {
    r: parseInt(values[0].toString() + values[0].toString(), 16),
    g: parseInt(values[1].toString() + values[1].toString(), 16),
    b: parseInt(values[2].toString() + values[2].toString(), 16),
  };
}

function lengthSix(values) {
  return {
    r: parseInt(values[0].toString() + values[1].toString(), 16),
    g: parseInt(values[2].toString() + values[3].toString(), 16),
    b: parseInt(values[4].toString() + values[5].toString(), 16),
  };
}
