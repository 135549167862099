// This fn is generic enough to be used with POIs and Boundaries.

// itemsTree describes hierachical dataset. It's an array of objects
// where one of the properties makes each object unique.
// selectedItemsTree is a key-value object where key is unique property value and value is the selected object from SQL API
// uniqueProperty is the property of itemsTree's objects that make them unique.
export default function parseHierarchicalData({
  itemsTree,
  selectedItemsTree,
  uniqueProperty,
}) {

  return [...itemsTree].reduce((usedItems, { [uniqueProperty]: column }, idx) => {
    let selectedItems = selectedItemsTree[column] || [];

    const childrenItems = itemsTree.slice(itemsTree.length - idx);
    if (childrenItems.length) {
      selectedItems = selectedItems.filter(({ id }) => {
        // Find if a child is already selected
        return !childrenItems.some(({ [uniqueProperty]: childColumn }) => {
          const childUsedCategories = usedItems[childColumn] || [];
          return childUsedCategories.find(
            ({ parents }) => {
              if (!parents) {return false}
              return parents[Math.max(0, parents.length - idx)] === id
            }
          );
        });
      });
    }

    if (selectedItems.length) {
      usedItems[column] = selectedItems;
    }

    return usedItems;
  }, {});
}
